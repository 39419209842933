body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before,
.slick-next:before {
  color: var(--dm-orange) !important;
}
.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  overflow-x: clip !important;
  overflow-y: visible !important;
  margin: 0 -10px;
}

.slick-dots li.slick-active button:before {
  color: var(--orange) !important;
}

textarea,
input,
select {
  outline: none;
}

html,
body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 60px;
}
h2 {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 40px;
}
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}
