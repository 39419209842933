:root {
  --pink: #f4ddcb;
  --lighter-yellow: #ffe7a5;
  --light-yellow: #f5ea7d;
  --yellow: #f1a80b;
  --orange: #e55411;
  --lighter-orange: #ffdba2;
  --light-orange: #efb456;
  --dark-orange: #8c2c00;
  --dm-orange: #ff793c;
  --light-red: #ffcb97;
  --red: #e41617;
  --white: #ffffff;
  --dark-text: #4e4e4e;
  --darker-text: #252525;
  --orange-grad: linear-gradient(90deg, var(--orange) 10%, var(--red) 200%);
}
/*Page Elements*/
body {
  font-family: "Roboto", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: var(--orange) transparent;
  position: static;
}

::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
a {
  text-decoration: none;
}
.colx {
  padding: 0;
  margin: 0;
}
.page-container {
  padding: 0 40px;
  position: relative;
}
.row {
  --bs-gutter-x: 0rem;
}
.ctfix {
  padding-top: 72px;
  overflow: hidden;
}
.ctspread {
  padding: 50px 0;
}
.jewel-ct-flex {
  display: flex;
  flex-direction: column;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.loading-container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px var(--white);
  border-bottom-color: var(--dm-orange);
  border-radius: 50%;
  content: "";
  height: 40px;
  width: 40px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
.jewel-error {
  text-align: center;
  padding: 10px 0;
  color: var(--dm-orange);
}
.ctfix .jewel-error {
  margin-bottom: 70vh;
}

/*Main Nav Elements*/
.header-nav {
  background: linear-gradient(90deg, #e55411 0%, #f5ea7d 61.25%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
  position: fixed;
  width: 100vw;
}
.nav-pos .row {
  z-index: 4;
  position: relative;
}
.jewel-logo {
  width: 120px;
  margin-left: 20px;
}
.jewel-logo-container {
  width: 200px;
  border-radius: 0px 79px 0px 79px;
  background-color: var(--white);
  overflow: hidden;
}
/*Nav List Elements*/
.jewel-nav-list-container {
  justify-content: end;
  display: flex;
}
.jewel-nav-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.jewel-nav-list li {
  display: inline;
  padding: 0 15px;
}
.jewel-nav-link {
  color: var(--dark-orange);
  transition: all 0.3s;
}
.jewel-nav-link:hover {
  color: var(--orange);
  font-weight: 600px;
  font-size: 20px;
  text-decoration: none;
}
/*Nav Dropdown Elements*/
.dropdown-icon {
  background-color: transparent;
  color: var(--dark-orange);

  font-size: larger;
  border: solid 2px var(--light-orange);
  padding: 10px;
  border-radius: 5px;
  transition: all 0.5s;
}
.drpdown-list {
  display: none;
  position: absolute;
  top: 100%; /* Position the menu right under the button */
  right: 0;
  animation: slideDown 0.3s ease; /* Add the slideDown animation */

  z-index: 1000;
  min-width: 10rem;
  margin: 0;
  font-size: 1rem;
  text-align: center;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}
.drpdown-relative {
  position: relative;
}
.drpdown-list.show {
  display: none;
}
.drpdown-item {
  padding: 2vh;
  text-align: center;
  display: block;
  color: var(--dark-orange);
}
.drpdown-item:hover {
  background-color: var(--light-orange);
  color: var(--white);
}
.jewel-nav-button {
  display: none;
}
.flip {
  background-color: white;
  color: var(--dark-orange);
  border: var(--yellow) 2px var(--light-orange);
}
/* The slideDown animation */
@keyframes slideDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.fixed-drop {
  position: fixed;
}
/*Nav Vector Styling*/
.nav-style {
  opacity: 0.8;
  position: absolute;
}
.nav-style-lower {
  top: 26px;
  left: 130px;
  transform: scale(0.8);
}
.nav-style-upper {
  top: -14px;
  left: -97px;
  transform: scale(0.8);
}
.nav-pos {
  position: relative;
  overflow: hidden;
  z-index: 5;
}

/*Banner*/
.jewel-banner {
  width: 100%;
  height: 100%;
  position: relative;
}
/* .jewel-banner .slick-track {
  display: flex !important;
}

.jewel-banner .slick-slide {
  height: inherit !important;
} */
.j-carousel {
  position: absolute !important;
  width: 100%;
  height: 100%;
}
.jewel-banner .slick-slide img {
  width: 100%;
}
.jewel-banner .slick-list {
  overflow: hidden;
  margin: 0;
}

.jewel-banner .slick-slider,
.jewel-banner .slick-list,
.jewel-banner .slick-track {
  height: 100%;
}

.jewel-banner .slick-slider * {
  height: 100%;
}
.jewel-banner .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-container::before {
  display: none !important;
}

.jewel-banner .slick-slide > div {
  margin: 0;
}

.jewel-msg {
  padding: 80px 0;
}
.announcement {
  padding-bottom: 25px;
  display: block;
  color: var(--white);
  text-shadow: 0 6px 7px rgba(0, 0, 0, 90);
}
.announcement-btn {
  padding: 7px 70px;
  display: block;
  font-size: larger;
  text-shadow: 0px 6px 7px rgba(0, 0, 0, 0.69);
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 41px;
}
.announcement-btn:hover {
  transform: scale(1.1);
}

.banner-container {
  height: 105vh;
  padding-top: 72px;
  overflow: hidden;
}
.banner-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(53, 59, 71, 0.18),
    rgba(53, 59, 71, 0.18)
  );
  top: 0;
  left: 0;
}
.hideBannerButton {
  display: none;
}

.bannerMenu {
  display: none;
  background-color: var(--orange);
  border-radius: 20px;
  padding: 20px 40px 40px;
  color: var(--white);
}
.bannerInstructions {
  font-size: large;
}
.bannerSearchForm {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.showBannerMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.searchFormRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0 10px;
}
.rowItem {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  width: inherit;
  height: 100%;
  margin: 0;
}
.labrowItem {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
  align-self: flex-end;
}
.searchFormRow label {
  padding-right: 10px;
  text-align: right;
}
.searchFormRow input {
  border: none;
  border-radius: 2px;
}
.searchFormButton {
  width: 40%;
  min-width: 200px;
  align-self: center;
  border: none;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 10px;
  color: var(--orange);
  font-size: large;
  border-radius: 30px;
}
/*New Listings*/
.new-listings {
  padding: 80px 0 100px;
}

.new-listings .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}

/*Rental Listings*/
.rental-listings {
  padding: 80px 0 100px;
  background-color: var(--lighter-yellow);
}

.rental-listings .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}

/*Lands Listings*/
.lands-listings {
  padding: 80px 0 100px;
  background-color: var(--lighter-orange);
}

.lands-listings .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}
/*Houses Listings*/
.houses-listings {
  padding: 80px 0 180px;
  background-color: var(--light-red);
}

.houses-listings .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}
/*Tree Svgs*/
.tree-vectors {
  position: relative;
}
.tree {
  color: var(--darker-text);
  position: absolute;
  width: 400px;
  height: 500px;
  max-width: 200px;
  max-height: 200px;
  min-width: 600px;
  min-height: 700px;
}
.t1 {
  top: -180px;
  left: -250px;
}
.t2 {
  top: -180px;
  right: -250px;
}

/*CommercialListings*/
.commercial-listings {
  padding: 80px 0 100px;
  background-color: var(--dark-text);
}

.commercial-listings .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}
/*Cards*/
.listings {
  transform: translateZ(0);
}
.card-container {
  height: 300px;
  width: 100%;
  overflow: hidden;
  object-fit: fill;
}
.card-obj {
  will-change: transform;
  /* margin-right: 20px; */
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.31);
  transition: all 0.5s;
}
.card-obj:hover {
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.21);
  transform: scale(1.05) translate3d(0, 0, 0);
}
.card-info-container {
  height: 50%;
  justify-content: center;
  padding: 0.8em;
  overflow: hidden;
}
.card-image-container {
  height: 50%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}
.card-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-name {
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
}
.card-cost {
  font-style: normal;
  font-weight: 700;
  font-size: xx-large;
  line-height: 35px;
  margin-bottom: 4px;
}
.card-rooms {
  margin: 0px;
}
.size {
  margin: 0px;
}
.section-title {
  margin-bottom: 30px;
}

/*Footer*/
.jewel-footer {
  align-items: center;
  justify-content: center;
  padding: 50px 0 20px;
  background-color: var(--darker-text);
  color: var(white);
}

.socials {
  color: var(--white);
  padding: 10px;
  font-size: xx-large;
  text-align: center;
}
.socials i {
  padding: 10px;
}
.footer-list {
  color: white;
  list-style: none;
  text-align: center;
  padding: 0;
}
.footer-list a {
  color: white;
}
.jewel-copyright {
  color: white;
}
.jewel-copyright i {
  padding-right: 8px;
}

/*Contact Screen*/
.contactTitle {
  margin-top: 1em;
}
.addressdetails {
  margin-bottom: 0.5rem;
}
.contact-ad-map {
  flex-grow: 1;
  margin-bottom: 20px;
}
.jewel-map {
  border: 1px solid var(--dm-orange);
  padding: 1px;
}

.contact-ct {
  flex-grow: 1;
  min-height: 80vh;
}
.contactAdditional {
  display: flex;
  flex-direction: column;
}

.contact-icons {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  font-size: 32px;
}
.contact-icons i {
  margin-right: 0.3em;
}

.insta-gradient {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  color: white;
  padding: 3px 4px;
  border-radius: 50px;
  font-size: 30px;
}
/*Gallery Screen*/

.gallery-container {
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}

.gallery-container .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}

.gallery-card {
  margin: 10px;
  width: 30%;
}
.gallery-card:last-child {
  align-self: flex-start;
}
/*Mission Screen*/

.mission-container {
  height: 90vh;
}

/*Single Item Screen*/
.si-image-container {
  width: 100%;
  height: 60vh;
  position: relative;
}
.si-floating {
  width: 100%;
  height: inherit;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 2;
}
.si-image-container .slick-prev,
.si-image-container .slick-next {
  height: 20px;
  z-index: 3;
}
.si-image-container .slick-prev {
  left: 3px;
}
.si-image-container .slick-next {
  right: 3px;
}
.si-image-container .slick-list {
  overflow: hidden;
  margin: 0;
}
.si-image-container .slick-slide > div {
  margin: 0;
}
.si-image-container .slick-slider,
.si-image-container .slick-list,
.si-image-container .slick-track {
  height: 100%;
}

.si-image-container .slick-slider * {
  height: 100%;
}
.si-image-container .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.si-image-container::before {
  content: "";
  position: absolute;
  display: relative;
  width: inherit;
  height: inherit;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.65));
  z-index: 1;
}
.si-title-container {
  position: absolute;
}
.si-title {
  color: var(--white);
  font-size: x-large;
  float: right;
  margin-bottom: 0;
}
.si-price {
  color: var(--dm-orange);
  font-size: 3rem;
  font-weight: bold;
  line-height: 60px;
  margin-bottom: 10px;
}
.si-address {
  color: var(--dm-orange);
  font-weight: bold;
  line-height: 35px;
}
.si-description-title {
  color: var(--dm-orange);
  font-size: x-large;
}
.si-description-body {
  text-align: justify;
  text-justify: inter-word;
}
.si-features {
  width: 100%;
  overflow: hidden;
  margin: 10px 0;
  border: 1px solid var(--orange);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
.si-features-heading-container {
  background: var(--orange-grad);
  color: var(--white);
  padding: 10px;
  text-align: center;
}
.si-features-heading {
  font-size: large;
}
.si-features-body-container {
  width: 300px;
  align-self: center;
}
.si-request-container {
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  border: none;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  width: 60%;
}
.si-request-title {
  text-align: center;
  font-size: x-large;
  color: var(--dm-orange);
}
.si-request-form {
  display: flex;
  flex-direction: column;
}
.si-request-form input::-webkit-outer-spin-button,
.si-request-form input::-webkit-inner-spin-button {
  display: none;
}
.si-request-form > * {
  margin-top: 10px;
}
.si-request-form label {
  color: var(--dm-orange);
}
.si-request-form textarea {
  resize: none;
}
.si-button {
  background: var(--orange-grad);
  color: var(--white);
  padding: 4px 2px;
  width: 50%;
  min-width: fit-content;
  border: none;
  transition: all linear 0.1s;
  border: 1px solid transparent;
  border-radius: 30px;
  align-self: center;
  font-weight: bold;
}
.si-button:hover {
  background: var(--white);
  border: 1px solid var(--dm-orange);
  color: var(--dm-orange);
}
.si-map-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}
.si-map-heading {
  color: var(--dm-orange);
  font-size: xx-large;
}
.si-iframe-container {
  width: 100%;
  height: 100%;
}
.si-iframe-parent {
  width: 100%;
  height: 100%;
  position: absolute;
}
.si-map-marker-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.si-content-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
  margin: 30px 0;
}
.container-l,
.container-r {
  flex: 0 0 47%;
  padding: 10px;
}
.container-l > div,
.container-r > div {
  margin: 15px 0;
}
.container-r {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.more-items-title {
  color: var(--dm-orange);
  font-size: xx-large;
}
.more-items-container {
  margin-bottom: 50px;
}
.more-items-container .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}
/*single-item mobile*/
.sim-title-container {
  display: none;
}

.sim-sticky {
  display: none;
}
@media (max-width: 900px) {
  .si-request-container {
    display: none;
  }
  .container-l,
  .container-r {
    flex: 0 0 100%;
    padding: 0px;
  }
  .sim-sticky {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    padding: 10px 20px 20px;
    color: var(--white);
    background: var(--orange-grad);
    bottom: 0;
    left: 0;
    z-index: 4;
  }

  .sim-sticky-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sim-sticky-container i {
    font-size: xx-large;
    transition: transform 0.4s;
  }
  .sim-sticky-heading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  body,
  .singleitem-container {
    overflow: clip;
  }
  .sim-request-title {
    text-align: center;
    font-size: x-large;
    color: var(--white);
  }
  .sim-request-form {
    display: none;
    flex-direction: column;
    width: 100%;
  }
  .form-active {
    display: flex !important;
  }
  .sim-request-form input::-webkit-outer-spin-button,
  .sim-request-form input::-webkit-inner-spin-button {
    display: none;
  }
  .sim-request-form > * {
    margin-top: 10px;
  }
  .sim-request-form label {
    color: var(--white);
  }
  .sim-request-form textarea {
    resize: none;
  }
  .button-active {
    transform: rotateZ(180deg);
  }
  .sim-button {
    margin-top: 20px;
    background: var(--white);
    color: var(--orange);
    padding: 4px 2px;
    width: 50%;
    min-width: fit-content;
    border: none;
    transition: all linear 0.1s;
    border: 1px solid transparent;
    border-radius: 30px;
    align-self: center;
    font-weight: bold;
  }
  .si-button:hover {
    background: var(--orange-grad);
    border: 1px solid var(--white);
    color: var(--white);
  }
}

@media (max-width: 520px) {
  .si-image-container {
    min-height: unset;
  }
  .si-floating {
    display: none;
  }
  .si-image-container::before {
    display: none;
  }
  .sim-title-container {
    text-align: center;
    display: block;
    background: var(--orange-grad);
    padding: 10px 0 5px 0px;
  }
  .sim-title {
    color: var(--white);
    font-size: x-large;
    margin-bottom: 0px;
  }
  .sim-price {
    color: var(--white);
    font-size: xx-large;
    line-height: 50px;
    font-weight: bold;
    margin-bottom: 0px;
  }
  .si-address h2 {
    text-align: center;
  }
  .si-address {
    margin-bottom: 55px !important;
  }
}

/*Medium devices (desktops, 992px and down)*/
@media (max-width: 994px) {
  h1 {
    font-size: 45px;
  }
  .jewel-msg {
    padding: 0px 0px;
    text-align: center;
  }

  .banner-container {
    padding-top: 60px;
  }

  .page-container {
    padding: 0 20px;
  }
  .ctfix {
    padding-top: 60px !important;
  }
  .jewel-logo {
    width: 100px;
  }
  .gallery-card {
    width: 46.5%;
  }
}
@media (max-width: 785px) {
  li a {
    font-size: smaller;
  }
}
@media (min-width: 767px) and (max-width: 768px) {
  .col-12.colx {
    width: 30%;
  }
  .col-12.col-md-8.colx {
    width: 70%;
  }
}
/*Small devices (tablets, 768px and down)*/
@media (max-width: 767px) {
  .drpdown-list.show {
    display: block;
  }
  .page-container {
    padding: 0 20px;
  }
  .jewel-nav-list-container {
    display: none;
  }
  .jewel-nav-button {
    display: block;
  }
  .card-container {
    height: 300px;
    overflow: hidden;
  }
  /*Cards*/

  .card-name {
    margin-bottom: 13px;
  }
  .card-cost {
    font-size: x-large;
    margin: 0px;
  }

  .card-rooms {
    margin: 0px;
  }
  .size {
    margin: 0px;
  }

  .section-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .section-info {
    text-align: center;
    margin-bottom: 60px;
  }
  .bannerMenu {
    padding: 20px 10px;
    min-width: 300px;
  }
  .bannerSearchForm {
    width: 88%;
    min-width: 280px;
  }
  .searchFormRow label {
    padding-left: 0;
  }
  .searchFormRow input {
    width: 50%;
  }
  .gallery-card {
    width: 100%;
  }
}

@media (min-width: 400px) and (max-width: 767px) {
  .bannerMenu {
    padding: 40px 20px;
    min-width: 300px;
  }
  .bannerSearchForm {
    width: 70%;
    min-width: 280px;
  }
}
@media (min-width: 810px) and (max-width: 1400px) {
  .card-cost {
    font-size: x-large;
  }
}
@media (min-width: 760px) and (max-width: 810px) {
  .card-cost {
    font-size: large;
  }
}
@media (max-width: 520px) {
  .card-cost {
    font-size: large;
  }
  .gallery-card {
    width: 90%;
  }
}
@media (max-width: 300px) {
  .card-name {
    font-size: x-small;
  }
  .card-cost {
    font-size: medium;
  }
}
/*Rotation*/
@media (max-height: 600px) {
  .banner-container {
    height: 160vh;
  }
  .si-image-container {
    height: 70vh;
    min-height: unset;
  }
  .si-floating {
    min-height: unset;
  }
  .si-image-container::before {
    min-height: unset;
  }
}
@media (max-height: 400px) {
  .banner-container {
    height: 190vh;
  }
}
@media (max-height: 300px) {
  .banner-container {
    height: 700px;
  }
}
/*Generic*/

.fullcnt {
  width: 100%;
  height: 100%;
}
.jewel-transparent {
  background-color: transparent;
  color: var(--white);
  font-weight: bold;
  border-color: var(--white);
  border-width: 1px;
  border-style: solid;
  transition: all 0.2s;
}
.jewel-transparent:hover {
  background-color: var(--orange);
  border-color: transparent;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
  cursor: pointer;
}
.jewel-transparent-alt {
  margin-top: 70px;
  background-color: transparent;
  color: var(--orange);
  align-self: center;
  font-weight: bold;
  border-color: var(--orange);
  border-width: 1px;
  border-style: solid;
  transition: all 0.2s;
  padding: 15px 10px;
  text-align: center;
}
.jewel-transparent-alter {
  color: var(--dm-orange);
  border-color: var(--dm-orange);
}
.jewel-transparent-alt:hover {
  background-color: var(--orange);
  border-color: transparent;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
}
.jewel-admin-btn {
  background-color: var(--orange);
  border-color: transparent;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
  width: 80%;
  padding: 8px;
  font-size: large;
  margin-top: 15px;
}

.jewel-transparent-alter:hover {
  background-color: var(--dm-orange);
}

.jewel-rounded {
  border-radius: 22px;
}

.jewel-small {
  width: 10%;
  min-width: 40vw;
}
.jewel-md {
  width: 30%;
  min-width: 160px;
}
.jewel-lg {
  width: 50%;
  display: block;
}
.jewel-nm {
  margin: 0;
}
.jewel-orange {
  color: var(--orange);
}
.jewel-btn-orange {
  color: var(--white);
  background-color: var(--orange);
  border: 1px solid transparent;
}
.jewel-btn-orange:hover {
  color: var(--orange);
  font-weight: bold;
  border: 1px solid var(--orange);
  background-color: transparent;
}
.jewel-black {
  color: var(--dark-text);
}
.jewel-vblack {
  color: var(--darker-text);
}
.jewel-vorange {
  color: var(--dark-orange);
}
.jewel-dmorange {
  color: var(--dm-orange);
}
.jewel-white {
  color: var(--white);
}
.jewel-red {
  color: var(--red);
}

/* Main Admin Screen*/
.login-container {
  padding-top: 72px;
}
/* Sidebar */
.navbar-aside {
  max-width: 260px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 3;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
  transition: left 0.3s ease-in-out;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.navbar-aside::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar-aside {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.navbar-aside .aside-top {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-aside.show {
  left: 0;
}

.navbar-aside.hide {
  left: -300px;
}
.menu-aside {
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}
.menu-aside a {
  display: block;
  text-decoration: none;
}

.menu-aside .menu-item {
  margin-bottom: 5px;
}
.menu-aside .menu-item .icon {
  color: #adb5bd;
  margin-right: 10px;
  font-size: 18px;
}
.menu-aside .active .icon {
  color: var(--orange);
}
.menu-aside .active {
  background-color: #ebf0fd;
}
.menu-aside .menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-aside .menu-link .text {
  vertical-align: middle;
}
.menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}
.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}
.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}
.menu-aside .submenu a:hover {
  color: #000;
}
.menu-aside .menu-item.active .submenu {
  display: block;
}
.menu-aside .menu-item.has-submenu > .menu-link:after {
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}
.sidebar-container {
  margin-top: 80px;
}
.mobile-block {
  display: none;
}

.btn-aside-show {
  display: none;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 40px;
  z-index: 2;
}
.btn-aside-show:hover {
  color: white;
}
.aside-show-icon {
  padding: 2px 13px 2px 0;
  color: white;
}
.aside-btn-menu-text {
  margin: 0;
}
.btn-aside-hide {
  display: none;
}
/* Interface Split */
.sidebar-out-container {
  width: 260px;
  flex-shrink: 0;
}
.interface-container {
  padding-top: 61px;
  display: flex;
  flex-direction: row;
}
.main-display {
  flex-grow: 1;
}

.jewel-admin-side-btn {
  background-color: var(--orange);
  border-color: transparent;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
  padding: 8px;
  font-size: large;
  margin-top: 30px;
}
.admin-search-container {
  width: 100%;
  text-align: center;
}
/*Admin Small devices (tablets, 768px and down)*/
@media (max-width: 700px) {
  .admin-header {
    text-align: center;
  }
  .btn-aside-show {
    display: flex;
  }
  .btn-aside-hide {
    display: flex;
  }
  .main-display {
    width: 100vw;
    padding-top: 80px;
  }
  .interface-container {
    flex-direction: column;
  }
  .admin-header {
    text-align: center;
  }
  .card {
    border: none !important;
    box-shadow: none !important;
  }
  .admin-top-initial-container {
    text-align: center;
  }
  form .jewel-btn-orange {
    margin: 0 auto;
    font-size: larger;
    width: 50%;
    padding: 10px 20px;
    font-weight: bold;
  }
}
@media (min-width: 701px) {
  .navbar-aside.hide {
    left: 0px;
  }
}

/* Admin Items */
.admin-main-container {
  margin: 30px 30px;
}
.admin-header {
  margin: 0px 0 20px;
}
.admin-main-container .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}
.admin-items-searchbar {
  margin: 20px 0;
  text-align: center;
  padding: 5px;
  font-size: large;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.admin-items-container .card-info-container {
  color: var(--white);
  background: var(--orange-grad);
}

.admin-items-card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  min-width: 300px;
  margin: 20px;
}
/*Flexy Stuff*/
@media (max-width: 600px) {
  .admin-items-card {
    flex-basis: calc(80% + 40px);
    min-width: unset;
  }
}
.admin-items-card:last-child {
  align-self: flex-start;
}

.buttons-edit-icon-container {
  float: right;
  padding-left: 5px;
}
.admin-edit-icon {
  margin-bottom: 10px;
  font-size: larger;
}
.admin-pencil-icon {
  color: lightseagreen;
}
.admin-delete-icon {
  color: var(--red);
}
/* Edit Item Details*/
.admin-header-backlink {
  float: right;
  display: inline;
}
.admin-inline {
  display: inline;
}
.admin-form-heading {
  font-size: x-large;
  font-weight: bold;
}
.admin-plus {
  color: white;
  background-color: var(--orange);
  border: none;
}
.main-display .form-label {
  font-weight: bold;
}
.admin-times {
  color: var(--orange);
  background-color: transparent;
  border: none;
}
.previewImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* dashboard */
.main-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-admin-stats-container {
}
.dashboard-r-container {
}
.admin-dashboard-bottom-container {
  height: 60px;
  padding-left: 30px;
}
.admin-main-chart {
  height: 500px;
  width: 500px;
  margin: 30px;
}
.top-cards .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 30px;
  border: none;
  box-shadow: 2px 2px 4px rgb(167, 167, 167);
}
.admin-card-content {
  font-size: 64px;
  font-weight: bold;
}
@media (max-width: 700px) {
  .admin-main-chart {
    height: 280px;
    width: 280px;
    max-width: 80vw;
    max-height: 80vw;
    margin: 20px 0px;
  }
  .admin-dashboard-bottom-container {
    text-align: center;
    margin-top: 20px;
    padding: 0px;
  }
}
.imPreview {
  height: 250px;
  max-width: 300px;
}
@media (max-width: 700px) {
  .imPreview {
    height: 200px;
    max-width: 250px;
  }
}

.admin-banner-area {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: flex-start;
}

.banner-image {
  width: 300px;
  height: 225px;
}

.banner-image-content {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.trash-window {
  width: 100%;
  text-align: center;
  color: white;
  background-color: orangered;
  padding: 0.8em;
  margin-top: 0.2em;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.trash-window:hover {
  background-color: #405de6;
  transform: scale(1.02);
  cursor: pointer;
}

@media (max-width: 640px) {
  .banner-image {
    width: 100%;
  }
}

.banner-upload-new {
  margin-top: 1em;
  width: 100%;
  text-align: center;
}

.banner-upload-new-button {
  width: 80%;
  color: white;
  background-color: #e69040;
  text-align: center;
  color: white;
  padding: 0.8em;
  margin-top: 0.2em;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  border: none;
}

.banner-upload-new-button:hover {
  background-color: #405de6;
  cursor: pointer;
}
